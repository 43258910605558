import * as React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import MainLayout from '../layouts/MainLayout'
import PageMainSection from '../components/reusable/PageMainSection/PageMainSection'
import ButtonPrimary from '../components/common/buttons/ButtonPrimary'
import CompanyFacts from '../components/Pages/Company/CompanyFacts/CompanyFacts'
import CompanyTeam from '../components/Pages/Company/CompanyTeam/CompanyTeam'
import CompanyStrategy from '../components/Pages/Company/CompanyStrategy/CompanyStrategy'
import CompanyValues from '../components/Pages/Company/CompanyValues/CompanyValues'
// eslint-disable-next-line import/no-named-as-default
import CompanyHistory from '../components/Pages/Company/CompanyHistory/CompanyHistory'
import MainBadges from '../layouts/MainBadges/MainBadges'

const Company = ({ data, location }) => {
  const { page_company: pageData } = data.wpPage
  const seoData = data.wpPage.metaTags
  return (
    <MainLayout seoData={seoData} location={location}>
      <PageMainSection
        hiddenTitle={seoData.seoHiddenTitle}
        title={pageData.titleDescription.title}
        description={pageData.titleDescription.description}
        image="company_bg.png"
        imageMobile="company_bg_mobile.png"
      >
        <ButtonPrimary buttonData={pageData.companyButton} />
      </PageMainSection>
      <CompanyFacts data={pageData} />
      <CompanyTeam data={pageData} />
      <CompanyStrategy
        heading={pageData.strategyHeading}
        items={pageData.strategyContent}
      />
      <CompanyValues data={pageData} />
      <CompanyHistory headings={pageData} data={data.allWpHistoryYear} />
      <MainBadges
        title={pageData.avardsHeading.title}
        description={pageData.avardsHeading.description}
      />
    </MainLayout>
  )
}
export const query = graphql`
  {
    allWpHistoryYear {
      nodes {
        historyYear {
          fieldGroupName
          year
          charts {
            people
            projects
            geography
            technologies
          }
          factItems {
            normalText
            strongText
          }
        }
        title
      }
    }
    wpPage(databaseId: { eq: 48 }) {
      metaTags {
        seoTitle
        seoDescription
        seoKeywords
        seoHiddenTitle
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }

      page_company {
        avardsHeading {
          description
          title
        }
        historyHeading {
          description
          title
        }
        valuesHeadings {
          description
          title
        }
        valueItems {
          description
          title
          proofItems {
            text
          }
          image {
            altText
            sourceUrl
          }
        }
        strategyHeading {
          description
          title
        }
        strategyContent {
          mission {
            description
            title
          }
          vision {
            description
            title
          }
        }
        teamHeading {
          description
          title
        }
        teamMembers {
          name
          title
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 405, width: 270)
              }
            }
          }
        }
        factsDescription
        factItemsTest {
          description
          number
        }
        titleDescription {
          description
          title
        }
        companyButton {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
      }
    }
  }
`

Company.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}
export default Company
