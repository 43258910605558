import React from 'react'
import Fade from 'react-reveal/Fade'
import * as style from './company-facts-section.module.scss'
import Section from '../../../common/SectionComponent/Section'
import keyGenerator from '../../../../utils/keyGenerator'

const CompanyFacts = ({ data }) => (
  <Section isLight hideText>
    <p>{data.factsDescription}</p>
    <div className={style.facts}>
      {data.factItemsTest.map((elem, index) => (
        <Fade key={keyGenerator(index, elem.number)} bottom>
          <dl className={style.fact}>
            <dt className={style.title}>{elem.number}</dt>
            <dd className={style.description}>{elem.description}</dd>
          </dl>
        </Fade>
      ))}
    </div>
  </Section>
)

export default CompanyFacts
