import React, { useState } from 'react'
import className from 'classnames'
import Fade from 'react-reveal/Fade'
import * as style from './company-values.module.scss'
import Section from '../../../common/SectionComponent/Section'
import keyGenerator from '../../../../utils/keyGenerator'

const CompanyValues = ({ data }) => {
  const [activeItem, setActiveItem] = useState(0)
  const activeItemToggle = (index) => {
    setActiveItem(index)
  }
  return (
    <Section title={data.valuesHeadings.title}>
      <p className="text-color__gray--on-dark">
        {data.valuesHeadings.description}
      </p>
      <div className={style.plates}>
        {data.valueItems.map((elem, index) => (
          // const image = getImage(elem.image.localFile.childImageSharp)
          <Fade bottom key={keyGenerator(index, elem.title)}>
            <div
              role="button"
              tabIndex={0}
              onClick={() => activeItemToggle(index)}
              onKeyDown={() => activeItemToggle(index)}
              onMouseEnter={() => activeItemToggle(index)}
              className={className(
                style.plate,
                activeItem === index && style.active
              )}
            >
              <img
                width={560}
                height={250}
                className={style.image}
                src={elem.image.sourceUrl}
                alt={elem.image.altText}
              />
              <h3 className={style.title}>{elem.title}</h3>
              <div className={style.text}>
                <p className={style.description}>{elem.description}</p>
                <div className={style.hiddenTextdescription}>
                  {elem.proofItems.map((item) => (
                    <p key={keyGenerator(index, item.text)}>{item.text}</p>
                  ))}
                </div>
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </Section>
  )
}

CompanyValues.defaultProps = {}

CompanyValues.propTypes = {}
export default CompanyValues
