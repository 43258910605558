// extracted by mini-css-extract-plugin
export var active = "company-history-module--active--r2DVw";
export var activeBlack = "company-history-module--activeBlack--CEeEs";
export var activeHide = "company-history-module--activeHide--LHORr";
export var animateCircle = "company-history-module--animate-circle--JVytA";
export var bounce = "company-history-module--bounce--cyGKR";
export var ldsRing = "company-history-module--lds-ring--booiZ";
export var wrap = "company-history-module--wrap--mOxGs";
export var yearItem = "company-history-module--yearItem--s-6zz";
export var yearsBar = "company-history-module--yearsBar--XAg4m";
export var yearsBarList = "company-history-module--yearsBarList--TOHuO";