// extracted by mini-css-extract-plugin
export var active = "company-values-module--active--Oj6ie";
export var animateCircle = "company-values-module--animate-circle--3zW4o";
export var bounce = "company-values-module--bounce--cAD4H";
export var description = "company-values-module--description--h5UZh";
export var hiddenTextdescription = "company-values-module--hiddenTextdescription--9xBv3";
export var image = "company-values-module--image--H7W-l";
export var ldsRing = "company-values-module--lds-ring--LKPR9";
export var plate = "company-values-module--plate--Zz84z";
export var plates = "company-values-module--plates--9lgeJ";
export var text = "company-values-module--text--UA3ij";
export var title = "company-values-module--title--tiz3r";