import * as React from 'react'
import { useEffect, useState } from 'react'
import className from 'classnames'
import * as style from './company-history-chart.module.scss'
import keyGenerator from '../../../../../utils/keyGenerator'
import useWindowSize from '../../../../../hooks/useWindowSize'

export const CompanyHistoryCharts = ({ data, activeItem }) => {
  const { width } = useWindowSize()

  const [active, setActive] = useState(0)
  const [windowWidth, setWindowWidth] = useState(width)
  const descriptions = [
    'Skilled professionals',
    'Solutions delivered',
    'Countries we partner with',
    'Areas of tech expertise',
  ]
  useEffect(() => {
    setWindowWidth(width)
  }, [width])

  const toggleActive = (index) => {
    setActive(index)
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  const GrowTableItem = ({ type, description, stat, index }) => {
    const init = windowWidth > 768 ? 200 : 135
    const [chartSize, setChartSize] = useState(init)
    useState(() => {
      const typeCoefficient =
        type === 'people'
          ? 0.4
          : type === 'geography'
          ? 0.6
          : type === 'technologies'
          ? 0.8
          : 1
      const maxValue = data[data.length - 1].historyYear.charts[type]
      const calcHeight = ((270 - chartSize) / maxValue) * stat * typeCoefficient
      const calcWidth = ((270 - chartSize) / maxValue) * stat * typeCoefficient
      const result =
        windowWidth > 768 ? calcHeight + chartSize : calcWidth + chartSize
      setChartSize(result)
    }, [index])

    return (
      <div
        tabIndex={0}
        role="button"
        onClick={() => toggleActive(index)}
        onKeyDown={() => toggleActive(index)}
        className={className(
          style.GrowTableItem,
          active === index && style.active
        )}
      >
        <div
          style={
            windowWidth > 767
              ? { height: `${chartSize}px` }
              : { width: `calc((${chartSize} * 100vw) / ${windowWidth})` }
          }
          className={style.GrowTableBg}
        />
        <div className={style.GrowTableItemText}>
          <p>{type}</p>
          <b>{stat}</b>
          <p>{description}</p>
        </div>
      </div>
    )
  }

  return (
    <div className={style.statsTables}>
      {Object.keys(data[activeItem].historyYear.charts).map((elem, index) => (
        <GrowTableItem
          key={keyGenerator(index, elem)}
          index={index}
          type={elem}
          description={descriptions[index]}
          stat={data[activeItem].historyYear.charts[elem]}
        />
      ))}
    </div>
  )
}

export default CompanyHistoryCharts
