import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import className from 'classnames'

import * as style from './company-history.module.scss'
import Section from '../../../common/SectionComponent/Section'
import keyGenerator from '../../../../utils/keyGenerator'
// eslint-disable-next-line import/no-named-as-default
import CompanyHistoryCharts from './CompanyHistoryCharts/CompanyHistoryCharts'
import CompanyHistoryFacts from './CompanyHIstoryFacts/CompanyHIstoryFacts'
import useWindowSize from '../../../../hooks/useWindowSize'

const year = `${new Date().getFullYear()}`

export const CompanyHistory = ({ data, headings }) => {
  const { width } = useWindowSize()

  const [active, setActive] = useState(data.nodes.length - 3)
  const [center, setCenter] = useState(0)
  const [windowWidth, setWindowWidth] = useState(width)

  const list = useRef()

  const descktopPositionCalc = () => {
    const itemSize = list.current.clientHeight
    setCenter(itemSize * 2 - (itemSize * active + 2))
  }
  const tabletPositionCalc = () => {
    const itemSize = list.current.clientWidth
    setCenter(itemSize * 2 - (itemSize * active + 2))
  }
  const mobilePositionCalc = () => {
    const itemSize = list.current.clientWidth
    setCenter(itemSize * 1 - (itemSize * active + 2))
  }
  const setCenterFunc = () => {
    if (windowWidth >= 1200) {
      descktopPositionCalc()
    } else if (windowWidth < 1200 && windowWidth > 768) {
      tabletPositionCalc()
    } else {
      mobilePositionCalc()
    }
  }
  useEffect(() => {
    setWindowWidth(width)
  }, [width])

  useEffect(() => {
    setCenterFunc()
  }, [windowWidth])

  useEffect(() => {
    setCenterFunc()
  }, [active])

  return (
    <Section isLight title={headings.historyHeading.title}>
      <p>{headings.historyHeading.description}</p>
      <div className={style.wrap}>
        <div className={style.yearsBar}>
          <ul
            key={windowWidth < 1200 ? center : ''}
            id="yearList"
            style={{
              transform:
                windowWidth < 1200
                  ? `translateX(${center}px)`
                  : `translateY(${center}px)`,
            }}
            className={style.yearsBarList}
          >
            {data.nodes.map((yearData, index) => (
              <li ref={list} key={keyGenerator(index, yearData.title)}>
                <button
                  type="button"
                  onClick={() => setActive(index)}
                  onKeyDown={() => setActive(index)}
                  className={className(
                    style.yearItem,
                    active === index && style.active,
                    (active + 1 === index || active - 1 === index) &&
                      style.activeBlack,
                    (active + 3 === index || active - 3 === index) &&
                      style.activeHide
                  )}
                >
                  {yearData.title}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <CompanyHistoryCharts data={data.nodes} activeItem={active} />
          {data.nodes.map((stat, index) => (
            <div key={keyGenerator(index)}>
              <CompanyHistoryFacts index={index} stat={stat} active={active} />
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default CompanyHistory
