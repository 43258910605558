// extracted by mini-css-extract-plugin
export var active = "company-history-facts-module--active--Ddy48";
export var animateCircle = "company-history-facts-module--animate-circle--1oa+3";
export var bounce = "company-history-facts-module--bounce--n8S0i";
export var ldsRing = "company-history-facts-module--lds-ring--HewuX";
export var moreBtn = "company-history-facts-module--moreBtn--fU--3";
export var statsFacts = "company-history-facts-module--statsFacts--ii-a6";
export var statsFactsText = "company-history-facts-module--statsFactsText--gabHz";
export var statsFactsWrap = "company-history-facts-module--statsFactsWrap--49DzD";
export var statsItem = "company-history-facts-module--statsItem--H5ooV";
export var statsTables = "company-history-facts-module--statsTables--H4Frz";
export var statsTitle = "company-history-facts-module--statsTitle--ELTtx";