import { useEffect, useRef, useState } from 'react'
import className from 'classnames'
import * as React from 'react'
import * as style from './company-history-facts.module.scss'
import keyGenerator from '../../../../../utils/keyGenerator'
import ButtonPrimary from '../../../../common/buttons/ButtonPrimary'

const CompanyHistoryFacts = ({ stat, index, active }) => {
  const [overflow, setOverflow] = useState(false)
  const [overflowState, setOverflowState] = useState(true)
  const [maxItems, setMaxItems] = useState(4)
  const title = useRef()
  const buttonOverflowToggle = () => {
    if (overflowState) {
      setMaxItems(stat.historyYear.factItems.length)
      setOverflowState(false)
    } else {
      setMaxItems(4)
      setOverflowState(true)
      setTimeout(() => {
        title.current.scrollIntoView({
          top: -500,
          behavior: 'smooth',
        })
      }, 300)
    }
  }

  useEffect(() => {
    setOverflow(stat.historyYear.factItems.length > 4)
  }, [active])

  return (
    <div
      className={className(style.statsItem)}
      key={keyGenerator(index, stat.title)}
    >
      <div ref={title} style={{ top: `-100px`, position: 'absolute' }} />
      <div
        className={className(
          style.statsFacts,
          active === index && style.active
        )}
      >
        <p className={style.statsTitle}>Key facts</p>
        <div className={className(style.statsFactsWrap)}>
          {stat.historyYear.factItems.map((key, i) => (
            <div key={keyGenerator(i, key.normalText)}>
              {i < maxItems && (
                <p className={style.statsFactsText}>
                  <span>{key.strongText} </span>
                  {key.normalText}
                </p>
              )}
            </div>
          ))}
        </div>
        {overflow && (
          <div onClick={buttonOverflowToggle}>
            <ButtonPrimary
              isLight
              arrowDirection={!overflowState ? 'up' : 'down'}
            >
              {!overflowState ? 'Show Less' : 'Show More'}
            </ButtonPrimary>
          </div>
        )}
      </div>
    </div>
  )
}

export default CompanyHistoryFacts
