import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import className from 'classnames'
import Fade from 'react-reveal/Fade'
import * as style from './company-team-section.module.scss'
import * as s from '/assets/scss/pages/businesses.module.scss'
import Section from '../../../common/SectionComponent/Section'
import keyGenerator from '../../../../utils/keyGenerator'

const CompanyTeam = ({ data }) => {
  const [activeItem, setActiveItem] = useState(0)
  const platesArray = data.teamMembers
  const nextSlide = () => {
    const next = activeItem === platesArray.length - 1 ? 0 : activeItem + 1
    setActiveItem(next)
  }
  const prevSlide = () => {
    const prev = activeItem === 0 ? platesArray.length - 1 : activeItem - 1
    setActiveItem(prev)
  }
  return (
    <Section title={data.teamHeading.title}>
      <p className="text-color__gray--on-dark">
        {data.teamHeading.description}
      </p>
      <div className={style.plates}>
        <button
          type="button"
          onClick={prevSlide}
          onKeyDown={prevSlide}
          className={className(s.slideButton, s.slideButtonPrev)}
        />
        {platesArray.map((elem, index) => {
          const image = getImage(elem.photo.localFile.childImageSharp)
          return (
            <div
              role="button"
              tabIndex={0}
              key={keyGenerator(index, elem.name)}
              onClick={() => setActiveItem(index)}
              onKeyDown={() => setActiveItem(index)}
              className={className(
                style.plate,
                activeItem === index && style.activePlate
              )}
            >
              <div className={style.image}>
                <div className={style.bg} />
                <GatsbyImage alt={elem.name} image={image} />
              </div>
              <div className={style.text}>
                <p className={style.name}>{elem.name}</p>
                <p className={style.title}>{elem.title}</p>
              </div>
            </div>
          )
        })}
        <button
          type="button"
          onClick={nextSlide}
          className={className(s.slideButton, s.slideButtonNext)}
        />
      </div>
    </Section>
  )
}

CompanyTeam.defaultProps = {}

CompanyTeam.propTypes = {}
export default CompanyTeam
