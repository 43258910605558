// extracted by mini-css-extract-plugin
export var activePlate = "company-team-section-module--activePlate--hW6k0";
export var animateCircle = "company-team-section-module--animate-circle--oMvIg";
export var bg = "company-team-section-module--bg--MisTX";
export var bounce = "company-team-section-module--bounce--iYzoD";
export var image = "company-team-section-module--image---4tWp";
export var ldsRing = "company-team-section-module--lds-ring--KS4BW";
export var name = "company-team-section-module--name--f1mVC";
export var plate = "company-team-section-module--plate--TEztP";
export var plates = "company-team-section-module--plates--3oZl3";
export var text = "company-team-section-module--text--Dik3o";
export var title = "company-team-section-module--title--I5K1W";